@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@font-face {
    font-family: 'Aileron-Regular';
    src: url('assets/fonts/Aileron-Regular.eot');
    src: url('assets/fonts/Aileron-Regular.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/Aileron-Regular.svg#Aileron-Regular') format('svg'),
         url('assets/fonts/Aileron-Regular.ttf') format('truetype'),
         url('assets/fonts/Aileron-Regular.woff') format('woff'),
         url('assets/fonts/Aileron-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Aileron-Bold';
    src: url('assets/fonts/Aileron-Bold.eot');
    src: url('assets/fonts/Aileron-Bold.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/Aileron-Bold.svg#Aileron-Bold') format('svg'),
         url('assets/fonts/Aileron-Bold.ttf') format('truetype'),
         url('assets/fonts/Aileron-Bold.woff') format('woff'),
         url('assets/fonts/Aileron-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  h4{
      font-size: 18px;
      font-family: 'Aileron-Regular', sans-serif; 
  }
  h5{
      font-size: 16px;
      font-family: 'Aileron-Regular', sans-serif; 
  }
  h1{
      
      font-family: 'Aileron-Bold', sans-serif; 
  }
  .text-height-1 {
    line-height: 1.5!important;
  }
  *:focus{outline: none;}
  body {background: #fff; font-family: 'Aileron-Regular', sans-serif; color:#373743; font-size:15px; line-height:22px; font-weight: 400;

    }
    a {text-decoration:none; 
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; outline: 0;}
        a:hover {text-decoration:none; outline: 0;}
    .mb-30{margin-bottom: 30px;}
    .mb-32{margin-bottom: 32px;}
    
.bg-dark {
    background-color: #292836!important;
    
}
.scrolled{background: #292836!important;}
.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 20px;
    margin-right: 20px;
}
.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
    color: #282836;
    border-bottom: 2px solid #f84241;
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: #282836;
    border-bottom: 2px solid #f84241;
}

.navbar-dark.scrolled .navbar-nav .nav-link:focus, .navbar-dark.scrolled .navbar-nav .nav-link:hover {
    color: #fff;
    border-bottom: 2px solid #f84241;
}
.logo{
    width:150px;
}

.navbar.scrolled .btn-secondary, .rblack-bg .btn-secondary,.footer .btn-secondary {
    color: #f84241;
    background-color: #fff;
    border-color: #fff;
}
.rblack-bg .btn-secondary:hover,.footer .btn-secondary:hover,.navbar.scrolled .btn-secondary:hover{
  color: #f84241;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
 .btn-secondary{
    color: #fff;
    background-color: #292836;
    border-color: #292836;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #282836;
  border-color: #282836;
}

.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  background-color: #282836;
  border-color: #282836;
  box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
}
.rblack-bg .btn-secondary.focus, .rblack-bg .btn-secondary:focus,.footer .btn-secondary.focus,.footer .btn-secondary:focus{
  color: #f84241;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
}
.rblack-bg .btn-secondary:not(:disabled):not(.disabled).active,.rblack-bg  .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #f84241;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #282836 radial-gradient(circle, transparent 1%, #282836 1%) center/15000%;
}
.ripple:active {
  background-color: #000;
  background-size: 100%;
  transition: background 0s;
}
.ripple1 {
  background-position: center;
  transition: background 0.8s;
}
.ripple1:hover {
  background: #ddd radial-gradient(circle, transparent 1%, #ddd 1%) center/15000%;
}
.ripple1:active {
  background-color: #fff;
  background-size: 100%;
  transition: background 0s;
}


.navbar-dark .navbar-nav .nav-link {
    color:#282836;
}
.navbar-dark.scrolled .navbar-nav .nav-link {
    color:#fff;
}
.navbar{
    padding:1rem;
}
.red-heading{
    color: #f84241;
    font-size: 40px;
    font-weight: bolder;
    line-height: 50px;
    text-transform: uppercase;
    font-family: 'Aileron-Bold', sans-serif;
}
.red-text{
    color: #f84241;
}
.banner{
    position: relative;
}
/* .header-content{
    padding:50px 50px 50px 150px;
}

.css-typing h3,.css-typing h1 {
    border-right: 1px solid #000;
    overflow: hidden;
    white-space: nowrap;
  }
  .css-typing h1{
    font-style: italic;
  } */

  /* .css-typing h3 {
    width: 7.3em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  } */
  
  /* .css-typing h3 {
    width: 18ch;
    text-transform: capitalize!important;
    opacity: 0;
    -webkit-animation: type2 3s steps(18);
    animation: type2 3s steps(18);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing h1 {
    width: 12ch;
    opacity: 0;
    -webkit-animation: type3 4s steps(12, end), blink .5s step-end infinite alternate;
    animation: type3 4s steps(12, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
   */
  /* @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: 1px solid #000;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: 1px solid #000;
    }
    100% {
      border: none;
    }
  }
   */
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: 1px solid #000;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: 1px solid #000;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    from, to { border-color: transparent }
  50% { border-color: black; }
  }
  @-webkit-keyframes blink {
    from, to { border-color: transparent }
    50% { border-color: black; }
  }
  

  .header-content{margin-top: 100px;}
  
.header-content h4{font-size: 24px;}
.banner-img{
     /* max-height: 700px; */
   /* height:100%; */
    margin: 150px auto 80px;
}
.rblack-bg{
    background: #292836 url(assets/images/red-bg.png) no-repeat center center;
   
    background-size: cover;
    padding: 80px 0;
position: relative;
}
.rblack-bg .box h5{font-size: 18px;font-weight: bold;}
.gray-bg{
    /* background: #f4f4f4; */
    padding: 100px 0;
    background: #f4f4f4 url(assets/images/bg.jpg) no-repeat center center;
   
    background-size: cover;

}
#count .box{
  max-height: 150px;height:100%; 
  border:1px solid #ddd; 
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;}
#logo .box,.claim-box .box{
   border:1px solid #ddd;
   -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#count .box:hover,#logo .box:hover,.claim-box .box:hover{
  box-shadow: 0 .1rem .5rem rgba(248,66,65,0.5)!important;
  -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      border:1px solid transparent;
}
#count h5{font-weight: bold; font-size: 20px;}
.heading{
    color: #000;
    font-size: 40px;
    font-weight: bolder;
    line-height: 50px;
    text-transform: uppercase;
    font-family: 'Aileron-Bold', sans-serif;
    display: inline-block;
    border-bottom: 3px solid red;
}
/* .heading::before,.w-heading::before{
  content: "";
  position: absolute;left:0;right:0;
  top: 50px;
  width: 50%;
  height:2px;
  background: red;
  margin: auto;
  display: inline-block;
} */
.w-heading{
    color: #fff;
    font-size: 40px;
    font-weight: bolder;
    line-height: 50px;
    text-transform: uppercase;
    font-family: 'Aileron-Bold', sans-serif;
    display: inline-block;
    border-bottom: 3px solid red;
}

.box{
    background: #fff;
    border-radius: 15px;
    padding:15px;
    position: relative;
    
}
#security{
    display: flex;
    align-items: stretch;
}
#security .box{
    max-height: 350px; 
    height:100vh;
}
.box:hover .inn-box{
    /* box-shadow: 0 .1rem .9rem rgba(248,66,65,0.8)!important; */
    box-shadow: 0 .1rem .5rem rgba(248,66,65,0.8)!important; 
    -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
}
.inn-box{
    background: #fff;
    border-radius: 15px; 
    /* box-shadow: 0 .1rem .5rem rgba(248,66,65,0.8)!important; */
    border:1px solid #ddd;
    width:80px;
    height:80px;
    -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
}


.text-red{
    color: #f84241;
    font-weight: bold;
}
.red-shadow{
    box-shadow: 0 .1rem .5rem rgba(248,66,65,0.8)!important;
}
.claim-box{
    position: relative;
    bottom:-40px;
}
#testimonial .claim-box{
    bottom:-40px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.white-sec{
    background:#fff;
    padding:70px 0;
    position: relative;
}
.rblack-bg .nav-pills .nav-link {
    border-radius: 8px;
    background: #fff;
    margin-bottom: 60px;
    color: #292836;
    padding:1rem;
}

.rblack-bg .nav-pills .nav-item:last-child .nav-link{
  margin-bottom: 0px;
}


.rblack-bg  .nav-pills .nav-link.active, .rblack-bg  .nav-pills .show>.nav-link{
background:#fff!important;

}

.rblack-bg  .nav-pills  .badge,.rblack-bg #accordion button .badge  {
    padding: 10px;
    font-size: 14px;
    margin-right: 10px;
    color: #f84241;
}
.rblack-bg  .nav-pills .nav-link.active .badge,.rblack-bg #accordion button:hover .badge{
    background:#f84241;
    color:#fff;
}
.rblack-bg #accordion button{
    color: #292836;
}
.rblack-bg #accordion button:focus{
    box-shadow: none;
}
#accordion .round-box{
    top:50px;
    position: relative;
}
.round-box{
    position: absolute;
    top:-70px;
    left:0;
    right:0;
    width:120px;
    height:120px;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    z-index: 1;
    background: #fff;
}
#security div{
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
#security div:hover .round-box{
    box-shadow: 0 .5rem 1rem rgba(248,66,65,0.8)!important;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.thumb-icon{
    width:100px;
    height:100px;
}
.thumb-img{
    max-height: 250px;
    /* height:100%; */
    margin: auto;
}



  .footer{
      background: #292836;
      padding: 100px 0 0;
      color:#fff;
  }
  .footer-logo{
      width:300px;
      margin: 0 auto 30px;

  }
  .footer-last{
      border-top: 2px solid #fff;
      
      margin-top: 30px;
  }
  .footer-last .nav .nav-link{
      color:#fff;
  }
  

  
  .footer-last .nav .nav-item.active .nav-link,.footer-last .nav .nav-link:hover{
      color:#f84241;
  }
.social-list{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
    margin-top:5px;
}
.social-list li{
    width:30px;
    height:30px;
    margin-left:10px;
}


.owl-item > div {
    cursor: pointer;
    margin: 0;
    transition: margin 0.4s ease;
    transform: scale(0.7);
    opacity: .75;
  }
  .owl-item.center > div {
    cursor: auto;
    margin: 0;
    transform: scale(1.1);
    opacity: 1;
  }
  .owl-item:not(.center) > div:hover {
    opacity: .75;
  }
  .owl-stage{padding:80px 0 50px;}


  .card{
    border: 2px solid #fff;
    box-shadow: 1px 1px 15px #ccc;
  }
  .card-body{
    background: #fff;
    width: 100%;
    vertical-align: top;
  }
  .card-content{
    text-align: center;
    color: #333;
  }
  .card-text{
    font-size: 14px;
    font-weight: 300;
  }

  .test-thumb{
    position: absolute;
    top:-50px;
  left:0;
  right:0;
  width:100px;
  height:100px;
  border-radius: 50%;
  margin: auto;
  border: 3px solid #f84241;
  text-align: center;
    
}
  .starrating > input {display: none;}  /* Remove radio buttons */

.starrating > label:before { 
  content: "\f005"; /* Star */
  margin: 2px;
  font-size: 14px;
  font-family: FontAwesome;
  display: inline-block; 
}

.starrating > label
{
  color: #222222; /* Start color when not clicked */
}

.starrating > input:checked ~ label
{ color: #ffca08 ; } /* Set yellow color when star checked */

.starrating > input:hover ~ label
{ color: #ffca08 ;  } /* Set yellow color when star hover */

/*------------- Form ---------------*/

.nb-form {
    position: fixed;
    z-index: 9999;
    width: 300px;
    background: #282836;
    top: 100px;
    right: -300px;
    transition: all .8s cubic-bezier(.22, .67, .43, 1.22) .2s;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12),0 3px 1px -2px rgba(0,0,0,0.2);
}
/* .nb-form:hover {
    right: 0px;
} */
 .nb-form.main {
    right: 0px!important;
}

/*-- User Icon --*/

.nb-form .user-icon {
    position: absolute;
    top: 150px;
    left: -35px;
    display: block;
    margin: 20px auto 15px;
    width:35px;
    cursor: pointer;
    
}

.nb-form .close-icon {
    position: absolute;
    top: 20px;
    right: 10px;
    display: block;
    width: 15px;
    cursor: pointer;
    
}

/*-- Title --*/

.nb-form .title {
    background: #f84242;
    font-size: 16px !important;
    padding: 20px 18px !important;
    color: #fff !important;
    border-radius: 10px 10px 0 0;
}

/*-- Text --*/

.nb-form p {
    font-size: 13px;
    margin: 0;
    padding: 15px;
    color: #fff;
}



/*-- Form Elements --*/

.nb-form form {
    padding: 15px;
}

.nb-form input,
.nb-form textarea {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    /* width: 254px;
    max-width: 254px; */
    margin-bottom: 10px;
    /* margin-left: 7px; */
    padding: 6px;
    border: none;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #fff;
    background: #6c6c75;
}
.nb-form input.form-control:focus, 
.nb-form textarea.form-control:focus {
    color: #fff;
    background-color: #6c6c75;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
/* .nb-form input:focus,
.nb-form textarea:focus {
    outline: none;
    box-shadow: none;
} */

.nb-form input[type='submit'] {
    display: block;
    width: 120px;
    margin: 0 auto;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    cursor: pointer;
    transition: all .4s ease;
    color: #333 !important;
    border: none;
}

.nb-form input[type='submit']:hover {
    box-shadow: 0 3px 3px 0 rgba(248,66,66,0.07),0 1px 7px 0 rgba(248,66,66,0.02),0 3px 1px -1px rgba(248,66,66,0.1);
}

.nb-form textarea {
    min-height: 110px;
}


.nb-form ::-webkit-input-placeholder {
    color: #fff;
}

.nb-form ::-moz-placeholder{
    color: #fff;
}

.nb-form :-ms-input-placeholder {
    color: #fff;
}

.nb-form :-moz-placeholder {
    color: #fff;
}

.nb-form input[type='submit'] {
    background: #fff;
}

#side .nav-link.active,
#side .nav-link:hover,
#side .nav-link:focus{
    color: #111;
    border-bottom: 1px #000 dashed;
}

@media (max-width: 1060px){
    .red-heading,.heading{
        font-size: 30px;
        line-height: 40px;
    }

    /* .banner-img{
        max-height: 400px;
      
    } */
}
@media (max-width: 991px){
    .header-content {
        padding: 0px;
    }
    .header-content h3{font-size: 22px;}
    .header-content h1{font-size: 2.5rem;}
    .header-content h4{font-size: 16px;}
    #security .box{
        margin-bottom: 100px;
        max-height: 280px;
    }
    .navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link,.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{
        border-bottom: 2px solid transparent;
    }
}
@media (max-width: 767px){
    .header-content {
        padding:30px 0px;
        margin-top: 0;
    }
    .red-heading,.heading{
        font-size: 25px;
        line-height: 35px;
    }
    .header-content h3{font-size: 18px;}
    .navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {

        border-bottom: none;
    }
    .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    
        border-bottom: none;
    }
    .gray-bg{
        padding: 50px 0;
       
    }
    .gray-bg .red-text,.gray-bg .red-heading{
        color: #292836;
    }
    .banner-img{
        margin: 70px auto 0;
    }
    .social-list{
        align-items: center;
        justify-content: center;
        margin: 10px auto;
    }
    .btn-link:hover {
      
      text-decoration: none;
  }
  
}
@media (min-width: 576px) and (max-width:767px){
  .container, .container-sm {
      max-width: 100%;}
  }
@media (max-width: 500px){
    .footer-logo{
        width: 200px;
    }
    .footer h5{
        font-size: 16px;
    }
    .footer-last .nav-link{
        padding: .5rem;
    }
    .w-heading, .red-heading{
        font-size: 28px;
    }
    .header-content .display-4{
        font-size: 2.5rem;
    }
    .header-content h3{font-size: 16px;}
    #testimonial .claim-box{
        bottom: -75px;
    }
    .thumb-icon {
        width: 50px;
        height: 50px;
    }
 
}
@media (max-width: 400px){
  .header-content .display-4{
    font-size: 2rem;
}

}